.tina {
  width: 100%;
  height: 100vh;
  position: relative;
}

.tina-smaller {
  width: 100%;
  height: 50vh;
  position: relative;
}

.tina-smaller h1 {
  padding-top: 4rem !important;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tina-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  widows: 100%;
}

.tina-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tina-text p {
  font-size: 1.3rem;

  color: white;
  padding: 0.5rem 0 2rem 0;
}

@media screen and (max-width: 555px) {
  .tina-text h1 {
    padding: 10px 20px;
  }

  .tina-text p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }
}
